@media all and (min-width: 470px) and (max-width: 600px) {
    .Login_Wrapper {
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 20px;
    }

    .Login {
        display: flex;
        flex-direction: column;
        margin: 28px;
        align-items: center;
    }

    .Login_Headline {
        font-size: 23px;
    }

    .Login_Wrapper input {
        font-size: 14px;
    }

    .Login_SubHeadline {
        font-size: 14px;
    }

    .Login_Logo {
        height: 100px;
        width: auto;
    }

    .Login_Button {
        width: 180px;
        height: 47px;
    }
}

@media all and (max-width: 470px) {
    .Login_Wrapper {
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 20px;
        font-size: 12px;
    }

    .Login {
        display: flex;
        flex-direction: column;
        margin: 15px;
        align-items: center;
    }

    .Login_Headline {
        font-size: 19px;
    }

    .Login_SubHeadline {
        font-size: 12px;
    }

    .Login_Logo {
        height: 80px;
        width: auto;
    }

    .Login_Wrapper a {
        font-size: 12px;
    }

    .Login_Wrapper input {
        font-size: 12px;
        height: .9em;
    }

    .Login_Button {
        width: 140px;
        height: 40px;
    }

    .Login_Icon {
        width: 60px;
    }
}

@media all and (max-width: 370px) {
    .Login_Wrapper {
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 20px;
        font-size: 11px;
    }

    .Login {
        display: flex;
        flex-direction: column;
        margin: 10px;
        align-items: center;
    }

    .Login_Headline {
        font-size: 16px;
    }

    .Login_SubHeadline {
        font-size: 11px;
        text-align: center;
    }

    .Login_Logo {
        height: 60px;
        width: auto;
    }

    .Login_Wrapper a {
        font-size: 11px;
    }

    .Login_Button {
        width: 120px;
        height: 36px;
    }

    .Login_Wrapper input {
        font-size: 12px;
        height: .7em;
    }
}

@media all and (min-width: 600px) {
    .Login {
        width: 500px;
        display: flex;
        flex-direction: column;
        margin: 28px;
        align-items: center;
    }

    .Login_Headline {
        font-size: 28px;
        font-weight: 500;
        line-height: 42px;
        letter-spacing: 0.14994px;
    }

    .Login_Logo {
        height: 110px;
        width: auto;
    }

    .Login_SubHeadline {
        font-size: 1rem;
    }
}

.Login_Headline {
    font-family: "Actual",-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
}