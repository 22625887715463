@media all and (max-width: 600px) {
    .ForgotForm .MuiDialogContent-dividers {
        width: 90% !important;
    }

    .ForgotForm span.MuiTab-wrapper {
        font-size: 17px !important;
    }

    .ForgotForm input {
        font-size: 16px !important;
    }

    .ForgotForm .MuiDialog-paper {
        margin: 8px !important;
    }

    .ForgotForm .MuiButton-root {
        margin-bottom: 30px !important;
    }
}

@media all and (max-width: 320px) {
    .ForgotForm .MuiDialogContent-dividers {
        width: 90% !important;
    }

    .ForgotForm span.MuiTab-wrapper {
        font-size: 15px !important;
    }

    .ForgotForm input {
        font-size: 15px !important;
    }

    .ForgotForm .MuiDialog-paper {
        margin: 4px !important;
    }
}