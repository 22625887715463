:root {
  --dark-color: #000000;
  --light-color: #ffffff;
  --primary-dark-color: #002d56;
  --primary-light-color: #00adef;
  --secondary-dark-color: #8c8c8c;
  --secondary-light-color: #e6e6e6;

  --font-size-1: 3.375rem;
  --font-size-2: 2.25rem;
  --font-size-3: 1.5rem;
  --font-size-4: 1.125rem;
  --font-size-5: 0.9375rem;
  --font-size-6: 0.6875rem;

  --line-height: 1.625em;
}

h1 {
  font-size: var(--font-size-1);
}

h2 {
  font-size: var(--font-size-2);
}

h3 {
  font-size: var(--font-size-3);
}

h4 {
  font-size: var(--font-size-4);
}

h5 {
  font-size: var(--font-size-5);
}

h6 {
  font-size: var(--font-size-6);
}

p {
  font-size: var(--font-size-5);
  line-height: var(--line-height);
}

@font-face {
  font-family: 'Actual_Light';
  font-style: normal;
  font-weight: normal;
  src: url('./config/fonts/Actual_Light.eot');
  src: url('./config/fonts/Actual_Light.eot?#iefix') format('embedded-opentype'),
    url('./config/fonts/Actual_Light.woff') format('woff'),
    url('./config/fonts/Actual_Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Actual';
  src: local('Actual'),
    url('./fonts/actual/actual_light-webfont.woff2') format('woff2'),
    url('./fonts/actual/actual_light-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Actual';
  src: local('Actual'),
    url('./fonts/actual/actual_regular-webfont.woff2') format('woff2'),
    url('./fonts/actual/actual_regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Actual';
  src: local('Actual'),
    url('./fonts/actual/actual_medium-webfont.woff2') format('woff2'),
    url('./fonts/actual/actual_medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Actual';
  src: local('Actual'),
    url('./fonts/actual/actual_bold-webfont.woff2') format('woff2'),
    url('./fonts/actual/actual_bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

.toast-progress-bar {
  background: #00adef;
  height: 5px;
}

.toast-body {
  width: 350px;
  top: 45px;
  right: 40px;
  background: #e9e9e9;
  color: #000000;
  font-family: 'Actual_Light';
}

.toast-error-body {
  background: #e9e9e9;
  color: #000000;
  width: 350px;
  top: 45px;
  right: 40px;
  font-family: 'Actual_Light';
  font-size: 15px;
}

.Toastify__toast-body {
  padding-left: 6px;
}

.toast-error-progress-bar {
  background: #e83e3e;
  height: 5px;
}

@media only screen and (max-width: 340px) {
  .text-survey {
    font-size: 9px !important;
  }
}

@media only screen and (min-width: 480px) and (max-width: 768px) {
  .text-survey {
    font-size: 16px !important;
  }
}


@media only screen and (min-width: 340px) and  (max-width: 480px) {
  .Toastify__toast-container {
    top: 1em;
    width: 320px;
    left: unset;
    padding: 4px;
  }

  .text-survey {
    font-size: 11px !important;
  }

  .text-website {
    font-size: 11px;
  }
  .wrap-box-website {
    flex-direction: column !important;
  }
  .select-user {
    width: 100% !important;
    margin-bottom: 10px !important;
  }

  .btn-website {
    margin-left: 0px !important;
  }
}

.select-user {
  width: 95px;
}

.wrap-box-website {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 1em;
  }
  .wrap-search {
    justify-content: unset !important;
  }


  .text-bottom-register {
    width: 90px;
    font-size: 10px !important;
  }
  .container-bottom-registration {
    padding: 0 0px 20px 0px !important;
    margin-top: 20px;
  }
}

.container-bottom-registration {
  padding: 40px 30px 15px
}

.wrap-search {
    justify-content: flex-end;
}

.noti_body {
  overflow: hidden;
}

.noti_img {
  float: left;
  width: 32px;
}

.noti_img img {
  /*border: 1px solid red;*/
}

.noti_content {
  float: left;
  width: calc(100% - 32px);
  padding-left: 18px;
}

.filter_item:hover {
  color: var(--hovercolor) !important;
  background-color: var(--hoverbgcolor) !important;
}

.box1 {
  background-color: #e5f3fb !important;
  height: 130px;
  width: 205px;
}

.box100_only_success {
  background-color: #e5f3fb !important;
  height: 130px;
  width: 360px;
  text-align: center;
}

.number1 {
  margin-bottom: 10px;
  margin-top: 0;
}

.content1 {
  width: 82%;
  margin: auto;
  margin-top: -15px;
}

.wr_desc {
  min-height: 230px;
}

.wr_desc .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.23);
}

.public-DraftEditor-content {
  min-height: 128px;
  font-family: 'Actual';
  font-weight: 400;
  font-size: 15px;
}

.desc_product textarea {
  font-family: 'Actual';
  font-weight: 400;
  font-size: 15px;
}

.wr_btn-text_html {
  text-align: right;
  padding-right: 40px;
}

.wr_btn-text_html span {
  margin: -2px 5px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-bottom: 3px;
  display: inline-block;
  width: 42px;
  text-align: center;
}

.wr_btn-text_html span.active10 {
  border-bottom: 3px solid var(--primary-light-color);
}

.foo999 {
  border-color: rgba(0, 0, 0, 0.23);
}

.wr_status_filter {
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  /* border-bottom: 1px solid #ddd; */
  /* margin-bottom: 10px; */
}

.orderstatus_item {
  display: inline-block;
  cursor: pointer;
  margin-left: 25px;
  /* margin-right: 25px; */
  padding-bottom: 15px;
  padding-top: 16px;
  padding-right: 7px;
  font-weight: 100;
}

.orderstatus_item.active_status {
  font-weight: 100;
  color: #00adef !important;
  position: relative;
  /* border-bottom: solid;
  border-color: #00adef;
  padding-bottom: 18px; */
}

.orderstatus_item.active_status:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #00adef;
  bottom: 0;
  left: 0;
}

.orderstatus_item_number {
  color: #00adef;
  padding-right: 4px;
}

.orderstatus_item_text {
  /* color: red; */
}

.wr_loading_order {
  align-items: center;
  padding: 50px;
  background-color: white;
  justify-content: center;
  display: flex;
  width: 100%;
}

.td_product_name {
  max-width: 380px;
  overflow: hidden;
  text-overflow: ellipsis;
}

table.tablescrolly {
  margin-top: 50px !important;
  display: inline-block !important;
  overflow: auto !important;
  max-height: 400px !important;
}
table.tablescrolly .th div {
  margin-top: -50px !important;
  position: absolute !important;
  font-weight: 700;
  color: black;
  font-size: 15px;
}

table.tablescrolly td {
  padding: 0px 15px !important;
}

.wr_loading_approval_modules {
  text-align: center;
  margin-top: 30px;
}

li.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button {
  max-width: 500px;
  text-overflow: ellipsis;
  display: block;
}

.link1 {
  height: 51px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
  text-align: left;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  justify-content: flex-start;
  text-decoration: none;
}

.wr_uploading {
  height: 379px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wr_loading_product {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.wr_loading {
  height: 300px;
  background-color: #efefef;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 96%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}

.MuiMenuItem-root,
.MuiInputBase-input {
  font-family: 'Actual' !important;
  font-weight: 400;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #00adef !important;
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #00adef !important;
}

.MuiListItem-button:hover,
.MuiAutocomplete-option[data-focus='true'] {
  background-color: var(--hoverbgcolor,#c9e7f7) !important;
  color: var(--hovercolor,#00adef) !important;
}

.MuiPaper-elevation8 {
  box-shadow: none !important;
  border: 1px solid rgba(220, 225, 225, 1) !important;
}

@media screen and (min-width: 600px) and (max-width: 9000px) {
  .MuiMenu-paper {
    max-height: 50vh!important;
  }
}

.btn_disabled {
  pointer-events: none;
  color: #bbb !important;
  background-color: #efefef !important;
  border-color: #bbb !important;
}

.select1,
.select2,
.select3 {
  margin-bottom: 10px !important;
}

.tracking_indicator.notall {
  color: red;
}

.cell_order {
  font-family: 'Actual' !important;
}

.cell_expand {
  vertical-align: top !important;
  padding-top: 23px !important;
}

.cell_expand .cell_payment {
  margin-top: -2px !important;
}

.cell_expand .link_track {
  margin-top: -17px !important;
  margin-bottom: -3px !important;
}

.wr_expand {
  color: #8c8c8c;
}

.wr_expand p {
  font-size: 13px !important;
  margin-top: 9px;
  margin-bottom: 12px;
  font-family: 'Actual_Light';
}

.cancel_adding_tracking_number {
  fill: #de7e7e !important;
  margin-left: 10px;
  cursor: pointer;
  transform: translateY(2px);
}

.cancel_adding_tracking_number:hover {
  fill: #9e4b4b !important;
}

.wr_number_of_product {
  border: 1px solid rgba(0, 173, 239, 0.7);
  color: #00adef;
  border-radius: 50%;
  display: inline-block;
  width: 18px;
  height: 18px;
  text-align: center;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  line-height: 16px;
  font-family: 'Actual_Light';
}

.notall.wr_number_of_product {
  border: 1px solid #b10035 !important;
  color: #b10035;
}

.cell_company_name {
  width: 272px;
}

.disabled_btn {
  pointer-events: none;
}

.shipping_link {
  text-decoration: underline;
  color: #00adef;
}

.shipping_link:hover {
  text-decoration: underline;
}

.select1 > div {
  min-height: 40px !important;
  font-family: 'Actual' !important;
}

.btn_cancel,
.btn_save_multiple,
.btn_update {
  height: 40px;
  width: 90px;
  display: inline-block;
  border-radius: 6px;
  font-family: 'Actual';
}

.btn_cancel {
  border: 1px solid #8c8c8c;
  background-color: white;
  color: #8c8c8c;
}

.btn_cancel:hover {
  background-color: #e6e6e6;
  color: #8c8c8c;
}

.btn_save_multiple,
.btn_update {
  background-color: #00adef;
  border: 1px solid rgba(0, 0, 0, 0);
  color: white;
}

.btn_save_multiple:hover,
.btn_update:hover {
  background-color: #002d56;
  border: 1px solid rgba(0, 0, 0, 0);
  cursor: pointer;
}

.btn_update.disabled,
.btn_update.disabled:hover {
  background-color: #e6e6e6;
  border: 1px solid #8C8C8C;
  color: #8C8C8C;
  cursor: default;
}

button {
  outline: none;
}
ul[class], ol[class] {
  padding: 0px !important;
}

.btn_save_single {
  margin: 0 0 0 10px !important;
}

.select1 > div {
  min-height: 40px !important;
  font-family: 'Actual' !important;
}

@media screen and (max-width: 600px) {
  .page_title_wrapper {
    max-width: 85vw !important;
  }

  .page_title3 {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 500px) {
  .page_title {
    font-size: 22px !important;
  }

  .page_title2 {
    font-size: 18px !important;
  }

  .page_title3 {
    font-size: 17px !important;
  }
}

@media screen and (max-width: 400px) {
  .page_title {
    font-size: 18px !important;
  }

  .page_title3 {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 350px) {
  .page_title {
    font-size: 16px !important;
  }

  .page_title2 {
    font-size: 15px !important;
  }
}

.navbar_item {
  font-family: 'Actual' !important;
}
